import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Role } from "api/client/models/Role";
import { SuccessResponse } from "api/client/models/SuccessResponse";

import { RootState } from ".";

export type SerializedRole = Omit<Role, "createdAt" | "updatedAt"> & {
  createdAt?: number;
  updatedAt?: number;
};

interface LoginState {
  sucursales?: { name: string }[];
  token?: string;
  clientName?: string;
  cuenta?: string;
  idCliente?: number;
  features?: string[];
  roles?: SerializedRole[];

  /**
   * @deprecated Use token headers instead.
   */
  fechaToken?: number;

  /**
   * @deprecated Use roles instead.
   */
  permissions?: string[];
}

type SessionPayload = SuccessResponse & { cuenta: string };

const loginSlice = createSlice({
  name: "login",
  initialState: {} as LoginState,
  reducers: {
    guardaDatosSesion(state, action: PayloadAction<SessionPayload>) {
      const {
        token,
        client: clientName,
        cuenta,
        features,
        polls,
        roles,
      } = action.payload;
      state.sucursales = [...new Set(polls.map((p) => p.sucursales).flat())];
      state.token = token;
      state.clientName = clientName;
      state.cuenta = cuenta;
      state.idCliente = polls?.[0]?.clientId;
      state.features = features;
      state.roles = roles.map(({ createdAt, updatedAt, ...others }) => ({
        createdAt: createdAt?.getTime(),
        updatedAt: updatedAt?.getTime(),
        ...others,
      }));
    },
    cierraLaSesion(state) {
      state.token = undefined;
      state.clientName = undefined;
      state.features = undefined;
      state.roles = undefined;
      state.fechaToken = undefined;
      state.permissions = undefined;
    },
  },
});

export const { guardaDatosSesion, cierraLaSesion } = loginSlice.actions;
export const loginSelector = (state: RootState) => state.login;

export default loginSlice.reducer;
